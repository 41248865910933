<template>
  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-xs="12" vs-sm="10" class="pt-4">
      <div class="flex justify-center">
         <img v-if="appName ==='FlipPay'" src="@/assets/images/logo/flippay-logo.svg" width="auto" height="48px" alt="Flippay"/>
         <img v-else-if="appName==='RelloPay'" src="@/assets/images/logo/rellopay-logo.svg" width="auto" height="48px" alt="Rellopay"/>
      </div>
      <vs-card class="mt-3 md:mt-8 bg-transparent otp-block customer-login-card">
          <div slot="header" class="px-6 md:px-8 py-4 md:py-6 text-center">
            <feather-icon icon="UserIcon" svgClasses="stroke-current mid-blue w-8 h-8" class="mb-3" />
            <h3 class="text-xl font-normal leading-normal">Create a user account</h3>
          </div>
        <div class="customer-login-container px-6 lg:px-8 py-4 md:py-8">
         <p class="text-sm md:text-base font-light text-center px-0 lg:px-8">Configure your account log in details</p>

          <div class="onboard-login-content mt-6 md:mt-10 mb-2 md:px-4">
            <div class="pb-4 md:pb-8">
            <label for="email" class="w-full mb-0 text-base text-dark-blue font-light">Email</label>
            <vs-input
              v-validate="'required|email|min:3'"
              name="Email"
              icon-no-border
              icon-pack="feather"
              v-model="form.email"
              class="w-full"
            />
            <div class="text-danger text-sm pt-1" v-show="errors.has('Email')">
              {{ errors.first("Email") }}
            </div>
            </div>
            <div class="pb-4 md:pb-8">
            <label class="w-full mt-8 text-base text-dark-blue font-light mb-0" for="password">Password</label>
            <password
              name="Password"
              ref="password"
              class="border-transparent w-full"
              v-validate="{
                required: true,
              }"
              v-model="form.password"
              :toggle="true"
              :badge="false"
              :showStrengthMeter="false"
            />
            <div class="text-danger text-sm pt-1" v-show="errors.has('Password')">
              {{ errors.first("Password") }}
            </div>
            </div>
            <div class="pb-2">
            <label for="password" class="w-full text-base text-dark-blue font-light mt-8 mb-0">Confirm password</label>
            <password
              v-validate="'required|confirmed:password'"
              name="Confirm Password"
              icon-pack="feather"
              v-model="form.confirmPassword"
              class="w-full pass-check"
              type="password"
              :success="
                form.password === form.confirmPassword &&
                form.password !== '' &&
                form.confirmPassword !== ''
              "
              :toggle="true"
              :showStrengthMeter="false"
              :badge="false"
            />
            <div
              class="text-danger text-sm pt-1"
              v-show="errors.has('Confirm Password')"
            >
              {{ errors.first("Confirm Password") }}
            </div>
            </div>
            <vs-button
              v-round
              size="large"
              @click="createPassword"
              class="mt-8 lg:mt-10 w-full"
              :disabled="isButtonDisabled"
              >Create account</vs-button
            >
          </div>
        </div>
      </vs-card>
    </vs-col>
  </vs-row>
</template>

<script>
import Password from "vue-password-strength-meter";
import { mapActions } from "vuex";
import store from "../../../store/store";

export default {
  components: { Password },

  data() {
    return {
      logo: "",
      appName: process.env.VUE_APP_NAME ||"FlipPay",
      serverUrl: process.env.VUE_APP_API_URL,
      partnerId: this.$route.params.partnerId,
      hash : this.$route.query.hash,
      form: {
        introducerRequestedId: "",
        uniqueKey: "",
        email: "",
        password: "",
        confirmPassword: "",
      },
    };
  },

  methods: {
    ...mapActions("partner", [
      "createLoginAccount",
    ]),

    formattedUrl(url) {
      if (!url) return "";
      return url.includes("http") ? url : `https://${url}`;
    },

    async createPassword() {
      this.$vs.loading();
      const data = {
        partnerId:this.partnerId,
        hash:this.hash,
        formData: this.form
      };

      this.createLoginAccount(data)
        .then((res) => {
          this.$vs.loading.close();
          this.$router.push({name: "partner-dashboard"});
        })
        .catch((e) => {
          if (e.response.status==409) {
            this.$vs.notify({
              title: "Create account",
              text: "Given email is already exist",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
            });
          }

          this.$vs.loading.close();
        });
    },
  },

  computed: {
    isButtonDisabled() {
      return (
        this.form.email.length === 0 ||
        this.errors.has("Email") ||
        this.form.password.length === 0 ||
        this.errors.has("Password") ||
        this.form.confirmPassword.length === 0 ||
        this.errors.has("Confirm Password")
      );
    },
  },
};
</script>